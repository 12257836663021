import axios from "axios";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import StarLogo from "../images/icons/star.svg";

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 992 },
		items: 3,
	},
	desktop: {
		breakpoint: { max: 991, min: 768 },
		items: 2,
	},
	tablet: {
		breakpoint: { max: 767, min: 464 },
		items: 1,
	},
	mobile: {
		breakpoint: { max: 463, min: 0 },
		items: 1,
	},
};

const Testimonials = ({ title }) => {
	const data = useStaticQuery(graphql`
		query {
			flexLogo: wpMediaItem(title: { eq: "Flex-Icon-Navy" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			reviews {
				reviewCount
				reviews {
					author
					reviewBody
				}
			}
		}
	`);

	const reviews = data.reviews.reviews;

	// Function to remove duplicates
	const getUniqueReviews = (reviews) => {
		const uniqueReviews = new Set();
		return reviews.filter((review) => {
			const normalizedAuthor = review.author.trim().toLowerCase();
			const sanitizedReviewBody = sanitizeText(review.reviewBody);
			const combinedKey = `${normalizedAuthor} ${sanitizedReviewBody}`;
			if (!uniqueReviews.has(combinedKey)) {
				uniqueReviews.add(combinedKey);
				return true;
			}
			return false;
		});
	};

	// Sanitize text by removing markdown and newlines
	const sanitizeText = (text) => {
		return text
			.replace(/[\*\_]{1,2}([\s\S]+?)\1/g, "$2") // Remove markdown bold/italic
			.replace(/[\#\>]+[\s]+/g, "") // Remove markdown headers & blockquotes
			.replace(/!\[[^\]]*\]\([^\)]*\)/g, "") // Remove markdown images
			.replace(/\[[^\]]*\]\([^\)]*\)/g, "") // Remove markdown links
			.replace(/\n+/g, " ") // Replace newlines with space
			.trim();
	};

	const uniqueReviews = getUniqueReviews(reviews);

	// const num = 0;

	// useEffect(() => {
	// 	axios
	// 		.get(
	// 			`https://www.local-marketing-reports.com/external/showcase-reviews/widgets/5b051f6d635cc36d3e24d1d879030cfab3bec5e1`
	// 		)
	// 		.then((res) => {
	// 			const reviewData = res.data.results;
	// 			setReviews(reviewData);
	// 			setReviews(reviewData);
	// 			document.getElementById("loading-spinner").style.display = "none";
	// 			document.getElementById("loading-spinner-small").style.display = "none";
	// 		});
	// }, [num]);

	// const TESTIMONIALS = [
	// 	{
	// 		custName: "Damiaan van Zanen",
	// 		position: "Managing Director",
	// 		text: "Tony and Matt have assisted me and my team in three office moves now. We have been extremely satisfied with the outcome each time. They took time to really understand our needs as a team at that time, found several really great options for us to chose from and helped us right through the process. I highly recommend the Flex Office Solutions team if you are looking to make a change to your office space. These guys are experts in what they do and the results will speak for themselves!",
	// 		date: "July 2022",
	// 	},
	// 	{
	// 		custName: "Damiaan van Zanen",
	// 		position: "Managing Director",
	// 		text: "Tony and Matt have assisted me and my team in three office moves now. We have been extremely satisfied with the outcome each time. They took time to really understand our needs as a team at that time, found several really great options for us to chose from and helped us right through the process. I highly recommend the Flex Office Solutions team if you are looking to make a change to your office space. These guys are experts in what they do and the results will speak for themselves!",
	// 		date: "July 2022",
	// 	},
	// 	{
	// 		custName: "Damiaan van Zanen",
	// 		position: "Managing Director",
	// 		text: "Tony and Matt have assisted me and my team in three office moves now. We have been extremely satisfied with the outcome each time. They took time to really understand our needs as a team at that time, found several really great options for us to chose from and helped us right through the process. I highly recommend the Flex Office Solutions team if you are looking to make a change to your office space. These guys are experts in what they do and the results will speak for themselves!",
	// 		date: "July 2022",
	// 	},
	// ];

	return (
		<>
			<Container className="px-4 h-100 px-md-0">
				<Row className="pb-3 pb-lg-5">
					<Col className="text-center">
						<h2 className="fs-1">{title || "Testimonials"}</h2>
					</Col>
				</Row>

				<Carousel
					responsive={responsive}
					ssr={true}
					infinite={true}
					autoPlay={true}
					keyBoardControl={true}
					containerClass="carousel-container-new"
				>
					{uniqueReviews.map((review, index) => (
						<ReviewCard key={index} review={review} />
					))}
				</Carousel>
			</Container>
		</>
	);
};

const ReviewCard = ({ review }) => {
	var trimmedString = review.reviewBody.substr(0, 145);
	trimmedString =
		trimmedString.substr(
			0,
			Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
		) + "...";

	const [reviewBody, setReviewBody] = useState(review.reviewBody);
	const [linkText, setlinkText] = useState("Read More");
	const [expanded, setExpanded] = useState(false);

	const handleClick = () => {
		if (!expanded) {
			setReviewBody(review.reviewBody);
			setlinkText("Read Less");
			setExpanded(true);
		} else {
			setReviewBody(trimmedString);
			setlinkText("Read More");
			setExpanded(false);
		}
	};
	if (!expanded) setReviewBody(trimmedString);

	return (
		<div
			className="rounded m-3 px-4 mb-5  position-relative rev-height  pt-5 pb-4"
			style={{ boxShadow: "0px 1px 20px #00000029" }}
		>
			<StaticImage
				src="../images/icons/google.svg"
				className="rounded-circle mx-auto ms-2 mb-3"
				style={{
					maxHeight: "35px",
					maxWidth: "35px",
				}}
				alt={"Google Logo"}
			/>
			<h4 className="pb-0 mb-0">{review.author}</h4>
			<div className="d-flex mt-2">
				<img
					src={StarLogo}
					alt="Star"
					className=" me-2 d-none d-md-inline-block "
					style={{
						maxWidth: "20px",
					}}
				/>
				<img
					src={StarLogo}
					alt="Star"
					className=" me-2 d-none d-md-inline-block "
					style={{
						maxWidth: "20px",
					}}
				/>
				<img
					src={StarLogo}
					alt="Star"
					className=" me-2 d-none d-md-inline-block "
					style={{
						maxWidth: "20px",
					}}
				/>
				<img
					src={StarLogo}
					alt="Star"
					className=" me-2 d-none d-md-inline-block "
					style={{
						maxWidth: "20px",
					}}
				/>
				<img
					src={StarLogo}
					alt="Star"
					className=" me-2 d-none d-md-inline-block "
					style={{
						maxWidth: "20px",
					}}
				/>
			</div>

			{/* <p>{test.position}</p> */}
			<hr />
			<p>
				{review.reviewBody.length > 145 && <div>{reviewBody}</div>}
				{review.reviewBody.length <= 145 && <div>{review.reviewBody}</div>}
			</p>
			{review.reviewBody.length > 145 && (
				<a
					style={{ cursor: "pointer" }}
					className="card-link"
					onClick={handleClick}
				>
					{linkText}
				</a>
			)}
		</div>
	);
};

export default Testimonials;
